import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/images/copy.png';
const _hoisted_1 = {
  class: "recharge acea-row row-between-wrapper",
  id: "notRecharge"
};
const _hoisted_2 = {
  key: 0,
  class: "recharge-card"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "acea-row row-center-wrapper centerRcon",
  style: {
    "width": "100%"
  }
};
const _hoisted_5 = {
  style: {
    "color": "#A4A4A4"
  }
};
const _hoisted_6 = {
  class: "recharge-address"
};
const _hoisted_7 = {
  class: "reItems"
};
const _hoisted_8 = {
  class: "mb-8"
};
const _hoisted_9 = {
  class: "recharge-form__item el-dropdown-link",
  style: {
    "width": "100%"
  }
};
const _hoisted_10 = {
  class: "reItems"
};
const _hoisted_11 = {
  class: "mb-8"
};
const _hoisted_12 = {
  class: "reItems"
};
const _hoisted_13 = {
  class: "mb-8"
};
const _hoisted_14 = {
  class: ""
};
const _hoisted_15 = {
  class: "mb-8"
};
import { IMG_URL } from '@/config';
import { router } from '@/router';
import { ref } from 'vue';
import { uploadFile, currencysList, recharge, downLoads } from '@/http';
import { showToast } from 'vant';
import { useClipboard } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'recharge',
  emits: ['back'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const {
      t
    } = useI18n();
    const {
      copy,
      isSupported
    } = useClipboard();
    const fileList = ref([]);
    const path = ref('');
    const show = ref(false);
    const actions = ref([]);
    const amount = ref('');
    const hash = ref('');
    const _dwonload = async imgUrl => {
      const response = await downLoads(imgUrl);
      // 生成文件流的URL
      const url = window.URL.createObjectURL(new Blob([response.data]));
      // 创建一个<a>标签并设置相关属性
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.txt');
      // 将<a>标签添加到文档中，并触发点击事件来开始下载
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    };
    const _copy = val => {
      copy(val).then(() => {
        if (!isSupported.value) {
          return showToast(t('current_browser_does_not_support_copy'));
        }
        showToast(t('copy_successful'));
      });
    };
    const rechargeData = ref({});
    const rechargeIndex = ref(0);
    const _recharge = () => {
      recharge({
        currency_id: rechargeData.value[rechargeIndex.value].id,
        amount: amount.value,
        hash: hash.value,
        img: path.value
      }).then(({
        msg,
        code
      }) => {
        showToast(msg);
        if (code === 200) {
          emit('back', 0);
        }
      });
    };
    const afterRead = file => {
      const formData = new FormData();
      formData.append('file', file.file);
      uploadFile(formData).then(({
        data
      }) => {
        path.value = data;
      });
    };
    const typeSelect = item => {
      rechargeIndex.value = item.value;
      show.value = false;
    };
    const _currencysList = () => {
      currencysList({
        type: 'withdraw'
      }).then(({
        data
      }) => {
        rechargeData.value = data;
        data.map((item, index) => {
          actions.value.push({
            name: `${item.name}-${item.agreement.toUpperCase()}`,
            value: index
          });
        });
      });
    };
    _currencysList();
    const emitBacks = () => {
      emit('back', 0);
    };
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_button = _resolveComponent("van-button");
      const _component_arrow_down = _resolveComponent("arrow-down");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
      const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
      const _component_el_dropdown = _resolveComponent("el-dropdown");
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_uploader = _resolveComponent("van-uploader");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
        class: "back acea-row row-left",
        onClick: emitBacks
      }, [_createVNode(_component_van_icon, {
        name: "arrow-left",
        size: "20"
      }), _createElementVNode("span", null, _toDisplayString(_ctx.$t('deposit')), 1)]), _createElementVNode("div", _hoisted_1, [rechargeData.value[rechargeIndex.value] ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("img", {
        class: "recharge-card__qr",
        src: _unref(IMG_URL) + rechargeData.value[rechargeIndex.value].img,
        alt: ""
      }, null, 8, _hoisted_3), _createVNode(_component_van_button, {
        round: "",
        color: "#56F9AA",
        style: {
          "height": "32px",
          "color": "#333"
        },
        onClick: _cache[0] || (_cache[0] = $event => _dwonload(_unref(IMG_URL) + rechargeData.value[rechargeIndex.value].img))
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('newMe.saveQr')), 1)]),
        _: 1
      }), _createElementVNode("div", _hoisted_4, [_createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('recharge_address')) + ": ", 1), _createElementVNode("p", _hoisted_6, [_createTextVNode(_toDisplayString(rechargeData.value[rechargeIndex.value].addr) + " ", 1), _createElementVNode("img", {
        class: "recharge-card__copy",
        src: _imports_0,
        alt: "",
        onClick: _cache[1] || (_cache[1] = $event => _copy(rechargeData.value[rechargeIndex.value].addr))
      })])])])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_7, [_createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('please_select_withdrawal_currency')), 1), rechargeData.value.length ? (_openBlock(), _createBlock(_component_el_dropdown, {
        key: 0,
        trigger: "click",
        style: {
          "width": "100%"
        },
        onCommand: typeSelect
      }, {
        dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(actions.value, item => {
            return _openBlock(), _createBlock(_component_el_dropdown_item, {
              command: item
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1)]),
              _: 2
            }, 1032, ["command"]);
          }), 256))]),
          _: 1
        })]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_9, [_createTextVNode(_toDisplayString(rechargeData.value[rechargeIndex.value].name) + "-" + _toDisplayString(rechargeData.value[rechargeIndex.value].agreement) + " ", 1), _createVNode(_component_el_icon, {
          class: "el-icon--right"
        }, {
          default: _withCtx(() => [_createVNode(_component_arrow_down)]),
          _: 1
        })])]),
        _: 1
      })) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_10, [_createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('recharge_amount')), 1), _createVNode(_component_van_field, {
        border: false,
        placeholder: _ctx.$t('please_enter_recharge_amount'),
        class: "br-a__8 mb-18 vantInput",
        modelValue: amount.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => amount.value = $event),
        type: "number"
      }, null, 8, ["placeholder", "modelValue"])]), _createElementVNode("div", _hoisted_12, [_createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t('recharge_hash')), 1), _createVNode(_component_van_field, {
        border: false,
        placeholder: _ctx.$t('please_enter_recharge_hash'),
        class: "br-a__8 mb-18 vantInput",
        modelValue: hash.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => hash.value = $event)
      }, null, 8, ["placeholder", "modelValue"])]), _createElementVNode("div", _hoisted_14, [_createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t('upload_recharge_proof_payment_screenshot')), 1), _createVNode(_component_van_uploader, {
        modelValue: fileList.value,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => fileList.value = $event),
        "after-read": afterRead,
        class: "upload-box",
        "max-count": 1
      }, null, 8, ["modelValue"])]), _createElementVNode("div", null, [_createVNode(_component_van_button, {
        style: {
          "max-width": "436px",
          "margin": "20px auto"
        },
        color: "#56F9AA",
        block: "",
        round: "",
        onClick: _recharge
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('confirm')), 1)]),
        _: 1
      })])])], 64);
    };
  }
};